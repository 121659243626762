import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { MessageService } from 'primeng/api';
import { switchMap, catchError, of, map, filter } from 'rxjs';

import { SearchService } from '@offconon/core-api';

import * as SearchActions from './search.actions';

@Injectable()
export class SearchEffects {
  private actions$ = inject(Actions);
  private searchService = inject(SearchService);
  private messageService = inject(MessageService);

  fetchKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.fetchKeywords),
      switchMap((action: ReturnType<typeof SearchActions.fetchKeywords>) =>
        this.searchService
          .searchKeywordserviceList(action.page, action.pageSize, {
            business_id: action.businessId,
            language_id: action.languageId,
            service_type: action.serviceType,
            translations: action.translations,
            keyword_type: action.keywordType,
            service_id: action.serviceId,
          })
          .pipe(
            map((result: any) => {
              return result.results?.map((item: any) => item.keyword);
            }),

            map((keyword) => SearchActions.fetchKeywordsSuccess({ keyword: keyword || [] })),
            catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
          ),
      ),
    ),
  );
  fetchBulkKeywords$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.fetchBulkKeywords),
      filter((action) => action.translations !== undefined && action.translations.length > 0),
      switchMap((action: ReturnType<typeof SearchActions.fetchBulkKeywords>) =>
        this.searchService
          .searchKeywordList(action.page, action.pageSize, {
            business_id: action.businessId,
            language_id: action.languageId,
            service_type: action.serviceType,
            translations: action.translations,
            keyword_type: action.keywordType,
            service_id: action.serviceId,
          })
          .pipe(
            map((result: any) => {
              return result.results?.map((item: any) => item?.translations[0].translation);
            }),
            map((keywords) => SearchActions.fetchBulkKeywordsSuccess({ keyword: keywords || [] })),
            catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
          ),
      ),
    ),
  );

  createBulkKeyword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.createBulkKeyword),
      switchMap((action) =>
        this.searchService.searchBulkKeywordCreateToKeywordServiceCreate(action.keywordList).pipe(
          map((response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Keyword created successfully',
            });
            return SearchActions.createBulkKeywordSuccess({
              response: action.keywordList.keywords,
            });
          }),
          catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
        ),
      ),
    ),
  );

  fetchKeywordById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.fetchTranslations),

      switchMap((action) => {
        return this.searchService
          .searchKeywordtranslationList(1, 300, {
            id: action.searchParameters.universalLanguageTranslateid,
          })
          .pipe(
            map((user) =>
              // UserActions.onSuccessfullyFetchedUserList({
              //   userList: user?.results || ([] as any),
              // }),
              SearchActions.loadSearchFailure({ error: '' }),
            ),
            catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
          );
      }),
    ),
  );
  deleteKeyword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.deleteKeyword),
      switchMap((action) => {
        return this.searchService.searchKeywordDestroy(action.id).pipe(
          switchMap(() => {
            this.messageService.add({
              severity: 'info',
              summary: 'Delete',
              detail: 'The item has been deleted!',
            });
            return of(
              SearchActions.deleteKeywordSuccess({
                id: action.id,
              }),
            );
          }),
          catchError((error) => of(SearchActions.loadSearchFailure({ error }))),
        );
      }),
    ),
  );
}
