import { createAction, props } from '@ngrx/store';

import { KeyWord, PostBulkKeyWordServiceRequest } from '@offconon/core-api';

import { SearchEntity } from './search.models';

export const initSearch = createAction('[Search Page] Init');

export const loadSearchSuccess = createAction(
  '[Search/API] Load Search Success',
  props<{ search: SearchEntity[] }>(),
);

export const loadSearchFailure = createAction(
  '[Search/API] Load Search Failure',
  props<{ error: any }>(),
);

export const fetchKeywords = createAction(
  '[Search Page] Fetch Keywords',
  props<{
    page?: number;
    pageSize?: number;
    businessId?: number;
    languageId?: number;
    serviceType?: string;
    translations?: string[];
    keywordType?: any;
    serviceId?: number;
  }>(),
);
export const fetchBulkKeywords = createAction(
  '[Search Page] Fetch Bulk Keywords',
  props<{
    page?: number;
    pageSize?: number;
    businessId?: number;
    languageId?: number;
    serviceType?: string;
    translations?: string[];
    keywordType?: any;
    serviceId?: number;
  }>(),
);

export const fetchKeywordsSuccess = createAction(
  '[Search/API] Fetch Keywords Success',
  props<{ keyword: KeyWord[] }>(),
);
export const fetchBulkKeywordsSuccess = createAction(
  '[Search/API] Fetch Bulk Keywords Success',
  props<{ keyword: string[] }>(),
);

export const createBulkKeyword = createAction(
  '[Search/API] Create Bulk Keyword',
  props<{ keywordList: PostBulkKeyWordServiceRequest }>(),
);

export const createBulkKeywordSuccess = createAction(
  '[Search/API] Create Bulk Keyword Success',
  props<{ response: any }>(),
);

export const fetchTranslations = createAction(
  '[Search/API] Fetch Translations',
  props<{
    searchParameters: { type?: string; keywordId?: number; universalLanguageTranslateid?: number };
  }>(),
);
export const deleteKeyword = createAction('[Search/API]  Keyword delete', props<{ id: number }>());
export const deleteKeywordSuccess = createAction(
  '[Search/API] Keyword delete success',
  props<{ id: number }>(),
);
