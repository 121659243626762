import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { KeyWord } from '@offconon/core-api';

import * as SearchActions from './search.actions';
import { SearchEntity } from './search.models';

export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState extends EntityState<SearchEntity> {
  selectedId?: string | number; // which Search record has been selected
  loaded: boolean; // has the Search list been loaded
  error?: string | null; // last known error (if any)
  keyword: KeyWord[];
  bulkKeyword: string[];
}

export interface SearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: SearchState;
}

export const searchAdapter: EntityAdapter<SearchEntity> = createEntityAdapter<SearchEntity>();

export const initialSearchState: SearchState = searchAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  keyword: [],
  bulkKeyword: [],
});

const reducer = createReducer(
  initialSearchState,
  on(SearchActions.initSearch, (state) => ({ ...state, loaded: false, error: null })),
  on(SearchActions.loadSearchSuccess, (state, { search }) =>
    searchAdapter.setAll(search, { ...state, loaded: true }),
  ),
  on(SearchActions.loadSearchFailure, (state, { error }) => ({ ...state, error })),
  on(SearchActions.fetchKeywordsSuccess, (state, { keyword: search }) => ({
    ...state,
    keyword: search,
  })),
  on(SearchActions.fetchBulkKeywordsSuccess, (state, { keyword: search }) => ({
    ...state,
    bulkKeyword: search,
  })),
  on(SearchActions.deleteKeywordSuccess, (state, { id }) => ({
    ...state,
    keyword: state.keyword.filter((k) => k.id !== id),
  })),
  on(SearchActions.createBulkKeywordSuccess, (state, { response }) => {
    let temp = state.keyword;
    const resp = response.map((a: any) => {
      return { translations: [{ language_id: 0, translation: a }] };
    });
    temp = [...temp, ...resp];

    return {
      ...state,
      keyword: temp,
    };
  }),
);

export function searchReducer(state: SearchState | undefined, action: Action) {
  return reducer(state, action);
}
